import { useEffect, useState } from 'react';
import Web3Provider, { Connectors, useWeb3Context } from 'web3-react';
import BigNumber from 'bignumber.js';
import erc20Abi from './const/usdc.json';
import stakingAbi from './const/staking.json';
import './App.css';
import { ethers } from 'ethers';

const StakerInfo = ({ context, setInfoText, chain, infoText }) => {
  const [inputAmount, setInputAmount] = useState(0);
  const [transacting, setTransacting] = useState(false);
  const [stakeDuration, setStakeDuration] = useState('ONE');
  const [stakeId, setStakeId] = useState([]);
  const [amuntStaked, setAmountStaked] = useState();
  const [period, setPeriod] = useState();
  const [reward, setReward] = useState();
  const [endAt, setEndAt] = useState();
  const [data, setData] = useState([]);

  let ADD_STATE = 'NONE';

  async function addEpidaurusToken() {
    if (ADD_STATE === 'ADDING' || ADD_STATE === 'ADDED') return;
    ADD_STATE = 'ADDING';
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: '0xe0d4bf77A7c8657058f9fCC733AAb01C033F4d30',
          symbol: 'EPiD',
          decimals: 18,
          image:
            'https://static.wixstatic.com/media/d7833a_a93284905ee9484e968c336868a45572~mv2.png/v1/fill/w_32%2Ch_32%2Clg_1%2Cusm_0.66_1.00_0.01/d7833a_a93284905ee9484e968c336868a45572~mv2.png',
        },
      },
    });
    ADD_STATE = 'ADDED';
  }

  if (!context.active) {
    return null;
  }
  const { account, library } = context;

  const epidaurusContract = new ethers.Contract(
    chain.epidaurus,
    erc20Abi,
    library.getSigner()
  );
  const approveEpidaurus = async () => {
    const decimals = await epidaurusContract.decimals();
    const amount = new BigNumber(inputAmount)
      .multipliedBy(10 ** decimals)
      .toFixed();

    const allowance = await epidaurusContract.allowance(account, chain.staking);
    console.log('Allowance is', allowance);
    if (allowance < amount) {
      try {
        setTransacting(true);
        const tx = await epidaurusContract.approve(chain.staking, amount);
        await tx.wait();
        setTransacting(false);
        return amount;
      } catch (error) {
        setTransacting(false);
        console.log(error);
        return 0;
      }
    }
    return allowance;
  };

  const stakingContract = new ethers.Contract(
    chain.staking,
    stakingAbi,
    library.getSigner()
  );

  const stake = async () => {
    setInfoText('Step 1 of 2: Approving Epids spend');
    const approved = await approveEpidaurus();
    console.log('Got approval', approved);
    if (approved === -1) {
      return;
    }
    if (approved === 0) {
      setInfoText('Error: Could not allow Epids to spend, try again');
      return;
    }

    try {
      setInfoText('Step 2 of 2: Staking EPiDs...');
      setTransacting(true);
      let time =
        stakeDuration === 'ONE'
          ? 86400
          : stakeDuration === 'TWO'
          ? 3 * 86400
          : 6 * 86400;
      const tx = await stakingContract.stake(approved, time);
      setTransacting(false);
      if (tx.status) {
        setInfoText('Successfully staked EPiDs!');
      } else {
        setInfoText('Error: The transaction to Stake EPiDs failed!');
      }
    } catch (error) {
      setTransacting(false);
      console.log(error);
      setInfoText(
        'Error: There was a problem sending transaction to the network!'
      );
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const stakeData = [];
    const stakeInfo = async () => {
      const _userStakes = await stakingContract.totalUserStakes(account);
      let totalUserStakes = _userStakes.toNumber();
      for (let i = 0; i < totalUserStakes; i++) {
        // eslint-disable-next-line no-undef
        let stakeId = await stakingContract?.userStakes(account, i);
        let isStake = await stakingContract?.isStake(stakeId);
        let isStillStaked = await stakingContract?.isStillStaked(stakeId);
        let stakeInfo = await stakingContract?.getStakeInformation(stakeId);

        stakeData.push({
          staker: stakeInfo.staker.toString(),
          amount: stakeInfo.amount.toString(),
          dailyReward: stakeInfo.dailyReward.toString(),
          endsAt: stakeInfo.endsAt.toString(),
          stakingPeriod: stakeInfo.stakingPeriod.toString(),
        });
      }

      setData(stakeData);
    };
    stakeInfo();
  }, []);
  // console.log('i am working', data);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alighItems: 'center',
          marginTop: '50px',
        }}
      >
        <div className='wrapper'>
          <h1 style={{ textAlign: 'center', fontSize: '20px' }}>
            Stake Your Token
          </h1>
          <input
            className='inputAmount'
            placeholder='Enter Epidaurus Token Amount'
            type='number'
            value={inputAmount}
            onChange={(ev) => setInputAmount(ev.target.value)}
          />
          <h1 style={{ textAlign: 'center', fontSize: '20px' }}>
            Select Staking Duration
          </h1>
          {/* <span className='stakeTag'>Select Staking Duration</span> */}
          <div
            className='radioSelect'
            onChange={(event) => {
              setStakeDuration(event.target.value);
            }}
          >
            <div style={{ marginBottom: '10px' }}>
              <input
                style={{ fontSize: '16px', marginRight: '20PX' }}
                type='radio'
                value='ONE'
                name='months'
                defaultChecked
              />
              One Month
            </div>

            <div style={{ marginBottom: '10px' }}>
              <input
                style={{ marginRight: '20PX' }}
                type='radio'
                value='TWO'
                name='months'
              />
              Three Months
            </div>

            <div>
              <input
                style={{ marginRight: '20PX' }}
                type='radio'
                value='THREE'
                name='months'
              />
              Six Months
            </div>
          </div>
          <button
            style={{ marginTop: '20px' }}
            onClick={() => stake()}
            disabled={inputAmount > 0 ? false : true || transacting}
          >
            {transacting ? 'Transacting...' : 'Stake'}
          </button>
          <button
            style={{ marginTop: '20px' }}
            onClick={() => addEpidaurusToken()}
          >
            Add EPiD To Metamask
          </button>
          <a href='#' onClick={addEpidaurusToken}></a>
        </div>
      </div>
      <p>{infoText}</p>
      <hr />
      <div
      >
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '20px',
            paddingTop: '20px',
          }}
        >
          Your Stakes
        </h1>
        <table
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <tr>
            <th>From</th>
            <th> Amount </th>
            <th> Period</th>
            <th> Reward</th>
            <th>Ends At </th>
          </tr>
          <br />
          {/* {data.map((items, indexx) => {
            console.log('yes it is working', items);
          })} */}
          {data.map((item, index) => {
            return (
              <>
                <tr key={index} style={{ textAlign: 'center' }}>
                  <td style={{ marginTop: '20px' }}>{item.staker}</td>
                  <td>{item.amount} EPiD</td>
                  <td>{item.stakingPeriod / 86400} Month</td>
                  <td>{item.dailyReward} EPiD </td>
                  <td>
                    {new Date(parseInt(item.endsAt) * 1000).toISOString()}{' '}
                  </td>
                </tr>
                <br />
              </>
            );
          })}
        </table>
        {/* {data.map((item, index) => {
          console.log('yes it is working', item);
          return <p key={index}>{item.staker}</p>;
        })} */}
      </div>
    </div>
  );
};
export default StakerInfo;
