import Web3Provider, { Connectors, useWeb3Context } from 'web3-react'
import { useState, useEffect } from 'react'
import StakerInfo from './StakeInfos'
import { Audio } from 'react-loader-spinner'
const supportedChains = [
  {
    // Mainnet
    id: 137,
    name: 'Polygon Mainnet',
    epidaurus: '0xe0d4bf77A7c8657058f9fCC733AAb01C033F4d30',
    lptoken: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    presaler: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    staking: '0x521b577Df6FF3837d79B44ed14da99d8d0C79102',
  },
  {
    // Test
    id: 5,
    name: 'goerli',
    epidaurus: '0x0c361F14BC5ee78A07a6FB8891dad21A6cD5a0D0',
    lptoken: '0x3C30480b63f0b3567e0f8f85FdD23F6298E9d1d6',
    presaler: '0x3ACdef9a4CC559f83532d9dD9555daf6dFBbc7b5',
    staking: '0xF69BEFdDa6cD2c13263c5EB7491d8fB561F35a86',
  },
]

export default function ConnectPage({ chain, setInfoText, infoText }) {
  const context = useWeb3Context()
  const [connectionState, setConnectionState] = useState('')

  async function addPolygonNetwork() {
    return window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          chainName: 'Polygon Matic',
          rpcUrls: ['https://polygon-rpc.com/'],
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          blockExplorerUrls: ['https://polygonscan.com/'],
        },
      ],
    })
  }
  useEffect(() => {
    if (context.networkId && context.networkId === chain.id) {
      setConnectionState('CONNECTED')
    } else if (context.error) {
      console.log(context.error)
      setConnectionState('ERRORED')
    } else {
      setConnectionState('DISCONNECTED')
    }
  }, [context])
  return (
    <>
      {connectionState === 'DISCONNECTED' ? (
        <div
          style={{
            width: '100%',
          }}
        >
          <h3 style={{ textAlign: 'center', fontSize: '28px' }}>
            Epidaurus Staking
          </h3>
          <br />
          <br />
          <br />
          <button
            onClick={() =>
              context.setFirstValidConnector(['MetaMask'], {
                networkIds: [chain.id],
              })
            }
          >
            Connect Wallet
          </button>
        </div>
      ) : connectionState === 'CONNECTED' ? (
        <StakerInfo
          context={context}
          infoText={infoText}
          setInfoText={setInfoText}
          chain={chain}
        />
      ) : connectionState === 'ERRORED' ? (
        <>
          <div
            style={{
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Audio
              height="80"
              width="80"
              radius="9"
              color="black"
              ariaLabel="three-dots-loading"
              wrapperStyle
              wrapperClass
            />
            <p>Please switch to {chain.name} in your wallet</p>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  )
}
